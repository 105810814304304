import styled from "styled-components";
import { globalStyles } from '../../../consts/globalStyles';
const width = "448px";


const article = styled.div`
  display: grid;
  width: ${width};
  gap: 25px;
  margin-top: 50px;
`;

const session = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .content {
    width: ${width};
    .p {
      padding-bottom: ${globalStyles.size.sm};
    }
  }
  .endIntegration {
    &:disabled {
      background-color: #c6c6c6;
    }
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const progressWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;

  .cds--progress {
    width: 100%;
    .cds--progress-step {
      inline-size: 100%;

      .cds--progress-line {
        inline-size: 100%;
      }
    }
  }
`;

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0 10px 0;
  .listItemDirectionEnd {
    justify-self: end;
    .buttom {
      color: ${globalStyles.colors.linkPrimary};
    }
  }
`;

const textWithLink = styled.div`
  padding: ${globalStyles.size.sm} 0;
  .linkColor {
    padding-left: 5px;
    color: ${globalStyles.colors.linkPrimary};
  }
`;

const formWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: start;
`;

export {
  article,
  session,
  ListContainer,
  textWithLink,
  progressWrapper,
  formWrapper
};
