import React, { useState } from 'react';
import { ChevronRight, Connect, DataConnected } from '@carbon/icons-react';
import {
  Button,
  Link,
  Grid,
  Column,
  ProgressIndicator,
  ProgressStep,
} from '@carbon/react';
import { useNavigate } from 'react-router-dom';

import LayoutWithoutMenu from '../../../layouts/LayoutWithoutMenu/LayoutWithoutMenu';
import ButtonDefault from '../../../components/ButtonDefault/ButtonDefault';
import RenderLogo from '../../../components/RenderLogo/RenderLogo';
import Label from '../../../components/Label/Label';

import constsLogos from '../../../consts/logoConsts';

import * as S from './integrationAccountCreation.style';

export default function IntegrationAccountCreation() {
  const navigate = useNavigate();

  const [sucess, setSucess] = useState(false);

  const handleConectar = () => {
    setSucess(!sucess);
    navigate('/onboarding');
  };

  return (
    <LayoutWithoutMenu>
      <S.progressWrapper>
        <ProgressIndicator>
          <ProgressStep
            complete
            label='Autenticação'
            description='Passo 1: Quem é você?'
            secondaryLabel='Precisamos conhecê-lo'
          />
          <ProgressStep
            complete
            label='Cadastro'
            description='Passo 2: Se cadastrando em Sumply'
            secondaryLabel='Precisamos de detalhes'
          />
          <ProgressStep
            current
            label='Integrações'
            description='Passo 2: Conectar dados'
            secondaryLabel='Precisamos de dados!'
          />
        </ProgressIndicator>
      </S.progressWrapper>
      <S.formWrapper>
        <S.article>
          <h3>Garanta um fluxo contínuo e automatizado de informações!</h3>
          <h4>
            Otimize processos internos, eliminando o retrabalho e reduzindo
            erros.
          </h4>
          <p className='firstParagraph'>
            Com a integração, dados vitais como vendas, estoque e finanças são
            sincronizadas em tempo real, proporcionando uma visão holística do
            seu negócio.
          </p>
          <p>
            Tomada de decisões embasadas em informações atualizadas e precisas
            impulsiona a agilidade operacional, permitindo que sua equipe foque
            no crescimento do negócio, ao invés de se ocupar com tarefas manuais
            repetitivas.
          </p>
        </S.article>
        <S.session>
          {!sucess && (
            <div className='content'>
              <div className='p'>
                <Label text='Conecte-se ao seu ERP' />
              </div>
              <div>
                {constsLogos.map((item) => (
                  <S.ListContainer key={item}>
                    {RenderLogo(item)}
                    <div className='listItemDirectionEnd'>
                      <Button
                        size='md'
                        kind='ghost'
                        renderIcon={Connect}
                        iconDescription='Connect'
                        className='buttom'
                        onClick={handleConectar}
                      >
                        Conectar
                      </Button>
                    </div>
                  </S.ListContainer>
                ))}
              </div>
              <S.textWithLink>
                <Label>
                  O seu ERP não está na lista?
                  <Link className='linkColor'>Entre em contato</Link>
                </Label>
              </S.textWithLink>
              <ButtonDefault
                type='submit'
                className='endIntegration'
                disabled={true}
                renderIcon={() => <ChevronRight />}
              >
                Finalizar
              </ButtonDefault>
            </div>
          )}
          {sucess && (
            <div className='content'>
              <div className='p'>
                <Label text='Conecte-se ao seu ERP' />
              </div>
              <div>
                <S.ListContainer>
                  {RenderLogo('contaAzul')}
                  <div className='listItemDirectionEnd'>
                    <Button
                      size='md'
                      kind='ghost'
                      renderIcon={DataConnected}
                      iconDescription='DataConnected'
                      className='buttom'
                    >
                      Desconectar
                    </Button>
                  </div>
                </S.ListContainer>
              </div>
              <S.textWithLink>
                <Label>
                  O seu ERP não está na lista?
                  <Link className='linkColor'>Entre em contato</Link>
                </Label>
              </S.textWithLink>
              <ButtonDefault
                type='submit'
                className='endIntegration'
                renderIcon={() => <ChevronRight />}
              >
                Finalizar
              </ButtonDefault>
            </div>
          )}
        </S.session>
      </S.formWrapper>
    </LayoutWithoutMenu>
  );
}
