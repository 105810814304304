import { createContext, useState } from "react";

export const CreatePlanContext = createContext();

export default function CreatePlanProvider({ children }) {
  const [target, setTarget] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [channels, setChannels] = useState({
    fisica: 0,
    digital: 0,
    atacado: 0,
  });
  const [leadTime, setLeadTime] = useState([]);
  const [commercialDays, setCommercialDays] = useState([
    { day: 9, month: 3, name: "Páscoa" },
    { day: 15, month: 2, name: "Dia do Consumidor" },
    { day: 12, month: 5, name: "Dia dos Namorados" },
    { day: 14, month: 4, name: "Dia das Mães" },
    { day: 11, month: 7, name: "Dia dos Pais" },
    { day: 29, month: 10, name: "Black friday" },
  ]);
  const [personalizedDates, setPersonalizedDates] = useState([]);
  const [selectedCurveId, setSelectedCurveId] = useState(0);

  const allDates = commercialDays.concat(personalizedDates);
  const selectedDates = allDates.filter((date) => date.selected);

  const curves = [
    { id: 0, name: "Sugerida" },
    { id: 1, name: "Venda digital" },
    { id: 2, name: "Invernais" },
    { id: 3, name: "Verão" },
    { id: 4, name: "Comercial" },
  ];

  const selectedCurve = curves.find(
    (curve) => curve.id === selectedCurveId
  )?.name;

  const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const writeMonthLabel = (date) => {
    const [month] = date.split("-");
    const monthIndex = parseInt(month, 10);
    const monthAbbreviation = monthAbbreviations[monthIndex];

    const yearSuffix = date.slice(-2);

    return `${monthAbbreviation}/${yearSuffix}`;
  };

  const leadTimeData = [
    {
      id: "row-1",
      category: "Camisetas",
      leadTime: undefined,
      products: [
        { id: "012340", name: "Camiseta 1" },
        { id: "012341", name: "Camiseta 1" },
        { id: "012342", name: "Camiseta 1" },
      ],
    },
    {
      id: "row-2",
      category: "Jaquetas",
      leadTime: undefined,
      products: [
        { id: "098290", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "198291", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "298292", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "398293", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "498294", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "598295", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "698296", name: "Jaqueta jeans longa azul bolso frontal" },
      ],
    },
    {
      id: "row-3",
      category: "Calças",
      leadTime: undefined,
      products: [
        { id: "098290", name: "Calças" },
        { id: "098291", name: "Calças" },
      ],
    },
    {
      id: "row-4",
      category: "Bermudas",
      leadTime: undefined,
      products: [
        { id: "098292", name: "Bermudas" },
        { id: "098293", name: "Bermudas" },
      ],
    },
    {
      id: "row-5",
      category: "Acessórios",
      leadTime: undefined,
      products: [
        { id: "098294", name: "Acessórios" },
        { id: "098295", name: "Acessórios" },
      ],
    },
  ];

  return (
    <CreatePlanContext.Provider
      value={{
        target,
        setTarget,
        selectedMonths,
        setSelectedMonths,
        channels,
        setChannels,
        leadTime,
        setLeadTime,
        leadTimeData,
        commercialDays,
        setCommercialDays,
        personalizedDates,
        setPersonalizedDates,
        selectedDates,
        selectedCurveId,
        setSelectedCurveId,
        selectedCurve,
        writeMonthLabel,
        monthAbbreviations,
      }}
    >
      {children}
    </CreatePlanContext.Provider>
  );
}
