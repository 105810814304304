import React, { useState } from "react";
import { ComposedModal } from "@carbon/react";
import {
  ArrowDown,
  CheckmarkFilled,
  ChevronRight,
  CircleDash,
} from "@carbon/icons-react";

import * as S from "./ItemView.style";

import BusinessView from "../BusinessView/BusinessView";
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";

export default function ItemView({ item }) {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div className="item">
        <div style={{ backgroundImage: "url(https://picsum.photos/100)" }} />
        <S.Section>
          <span className="titulo">Categoria</span>
          <span className="nome">{item.nome}</span>
        </S.Section>
        <S.Section>
          <span
            className={`pill ${
              item.estoque.categoria === "Estoque em falta" ? "red" : "green"
            }`}
          >
            {item.estoque.categoria}
          </span>
          <span className="numero">
            {item.estoque.quantidade.toLocaleString("pt-br")}
          </span>
        </S.Section>
        <S.Section>
          <span className="titulo">Ações Sugeridas</span>
          <span className="numero">
            {item.acoes.length.toLocaleString("pt-br")}
          </span>
        </S.Section>
        <S.Section>
          <span className="titulo">ROI das Ações</span>
          <span className="numero">{item.ROI}</span>
        </S.Section>
        <div>
          <span className="titulo">Status</span>
          {item.status.map((status) => (
            <span className="status">
              <span>{status.titulo}</span>
              {status.completa ? (
                <CheckmarkFilled color="green" />
              ) : (
                <CircleDash />
              )}
            </span>
          ))}
        </div>
        <button className="openBtn" onClick={() => setOpen(!open)}>
          <ArrowDown />
        </button>
      </div>
      <div className={open ? "painel show" : "painel"}>
        <S.Title>
          <S.CategoryTitle>Categoria</S.CategoryTitle>
          <S.ItemTitle>{item.nome}</S.ItemTitle>
        </S.Title>
        <S.Content>
          <ul>
            <S.ListItem>
              Transferir 4.502 peças de Abril. Sugestão 2.500 peças para Maio;
              2.000 para Junho.
            </S.ListItem>
            <S.ListItem>Incluir compra de 2.750 peças em Maio</S.ListItem>
            <S.ListItem>Incluir compra de 700 peças em Junho</S.ListItem>
            <S.ListItem>Incluir compra de 2.750 peças em Julho</S.ListItem>
            <S.ListItem>Incluir compra de 750 peças em Agosto</S.ListItem>
          </ul>
          <ButtonDefault
            className="ajustarPlano"
            renderIcon={() => <ChevronRight />}
            onClick={() => setOpenModal(true)}
          >
            Ajustar Plano
          </ButtonDefault>
        </S.Content>
      </div>
      <S.Container>
        <ComposedModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          size="lg"
        >
          <BusinessView />
        </ComposedModal>
      </S.Container>
    </>
  );
}
