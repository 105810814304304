import React, { useContext } from "react";
import {
  Column,
  Grid,
  DataTable,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableExpandHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
  TextInput,
} from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";

import Icon from "../../../assets/icons/Delivery--truck.svg";
import * as S from "./LeadTimeStep.style";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

export default function LeadTimeStep({ onCompleteStep, onBackStep }) {
  const { leadTime, setLeadTime, leadTimeData } = useContext(CreatePlanContext);

  const headers = [
    { title: "Categorias", key: "category" },
    { title: "Tempo de entrega", key: "leadTime" },
  ];

  const getLeadTime = (id) => {
    return leadTime.find((category) => category.id === id)?.leadTime;
  };

  const defineLeadTime = (id, inputValue) => {
    let newLeadTime = leadTime;

    if (leadTime.find((category) => category.id === id)) {
      newLeadTime = leadTime.filter((category) => category.id !== id);
    }

    if (inputValue) {
      newLeadTime = [...newLeadTime, { id, leadTime: inputValue }];
    }
    setLeadTime(newLeadTime);
  };

  const areAllComplete = () => {
    const allCategoryIds = leadTimeData.map((category) => category.id);
    return allCategoryIds.every((id) =>
      leadTime.find((category) => category.id === id)
    );
  };

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="Tempo de entrega" />
          <h4>Tempo de entrega</h4>
        </S.Title>
        <S.Text>
          Agora você vai definir uma média de <strong>lead time</strong> por
          categoria. Pense no tempo em semanas entre fazer o pedido ao
          fornecedor e receber esse pedido em loja. Com esses inputs você poderá
          otimizar seus níveis de estoque, garantindo que seus produtos estejam
          disponíveis quando e onde são necessários. Utilizamos as categorias
          cadastradas para seus itens.
        </S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text margin="0 0 10px 0">
          Qual a média de semanas que leva desde você encomendar o produto com o
          fornecedor até ele chegar em loja?
        </S.Text>
        <DataTable rows={leadTimeData} headers={headers}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps,
          }) => (
            <TableContainer {...getTableContainerProps()}>
              <S.Table {...getTableProps()} size="md">
                <TableHead>
                  <TableRow>
                    <TableExpandHeader id="expand" />
                    {headers.map((header, i) => (
                      <TableHeader
                        id={header.title}
                        {...getHeaderProps({ header })}
                      >
                        {header.title}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIdx) => {
                    return (
                      <React.Fragment key={row.id}>
                        <TableExpandRow {...getRowProps({ row })}>
                          <TableCell>
                            {rows[rowIdx].cells[0].value || "Categoria"}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                maxWidth: "180px",
                              }}
                            >
                              <TextInput
                                placeholder="Tempo em dias"
                                type="number"
                                value={getLeadTime(row.id)}
                                onChange={(e) =>
                                  defineLeadTime(row.id, e.target.value)
                                }
                              />{" "}
                              dias
                            </div>
                          </TableCell>
                        </TableExpandRow>
                        <TableExpandedRow colSpan={headers.length + 1}>
                          {leadTimeData[rowIdx].products?.map((product) => (
                            <S.Product key={product.id}>
                              {product.id} - {product.name}
                            </S.Product>
                          ))}
                        </TableExpandedRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </S.Table>
            </TableContainer>
          )}
        </DataTable>

        {leadTime.length && !areAllComplete() ? (
          <S.Text color="red" margin="10px 0 0 0">
            Preencha o lead time de todas as categorias antes de seguir para a
            próxima etapa.
          </S.Text>
        ) : (
          <></>
        )}

        <S.ButtonSet>
          <S.Button kind="ghost">Editar categorias</S.Button>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(2);
            }}
          >
            <ArrowLeft /> Voltar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            disabled={!areAllComplete()}
            onClick={() => {
              onCompleteStep(2);
            }}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
