import styled from "styled-components";

const form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
  margin-top: 50px;
  .submitBtn {
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const formWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: start;
`;

const progressWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;

  .cds--progress {
    width: 100%;
    .cds--progress-step {
      inline-size: 100%;

      .cds--progress-line {
        inline-size: 100%;
      }
    }
  }
`;

export { form, formWrapper, progressWrapper };
