import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ModalHeader, ModalBody } from "@carbon/react";

import { CreatePlanContext } from "../../../contexts/CreatePlanContext";

import * as S from "./SummaryModal.style";

export default function SummaryModal({
  showSummaryModal,
  setShowSummaryModal,
}) {
  const {
    target,
    selectedMonths,
    channels,
    leadTime,
    leadTimeData,
    writeMonthLabel,
    selectedDates,
    selectedCurve,
    monthAbbreviations,
  } = useContext(CreatePlanContext);
  const navigate = useNavigate();

  const writePeriod = () => {
    if (selectedMonths.length) {
      return (
        writeMonthLabel(selectedMonths[0]) +
        `${
          selectedMonths.length > 1
            ? ` - ${writeMonthLabel(selectedMonths[selectedMonths.length - 1])}`
            : ""
        }`
      );
    } else return "";
  };

  const isChannelSelected = Object.values(channels).some(
    (channelValue) => channelValue > 0
  );

  const getCategoryName = (id) => {
    return leadTimeData.find((category) => category.id === id).category;
  };

  const writeCommercialDate = (date) => {
    const monthIndex = parseInt(date.month, 10);
    const monthAbbreviation = monthAbbreviations[monthIndex];

    return `${date.day}/${monthAbbreviation}`;
  };

  return (
    <S.ComposedModal
      open={showSummaryModal}
      onClose={() => setShowSummaryModal(false)}
    >
      <ModalHeader title="Confirmação dos Dados de Planejamento" />
      <ModalBody>
        <p>Antes de prosseguir, confira os dados que você inseriu.</p>

        <S.SectionList>
          <S.TargetContainer>
            <div>
              <h5>Período de planejamento</h5>
              <p>{writePeriod()}</p>
            </div>
            <div>
              <h5>Meta para o período</h5>
              <p>R$ {target}</p>
            </div>
          </S.TargetContainer>

          <div>
            <h5>Canais de venda</h5>

            {!isChannelSelected ? (
              <p>Nenhum canal de venda foi definido.</p>
            ) : (
              <div>
                {channels.fisica ? (
                  <S.Channel>
                    <p>Loja Física</p>
                    <p>{channels.fisica}%</p>
                  </S.Channel>
                ) : (
                  <></>
                )}
                {channels.digital ? (
                  <S.Channel>
                    <p>Digital</p>
                    <p>{channels.digital}%</p>
                  </S.Channel>
                ) : (
                  <></>
                )}
                {channels.atacado ? (
                  <S.Channel>
                    <p>Atacado</p>
                    <p>{channels.atacado}%</p>
                  </S.Channel>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          <div>
            <h5>Tempo de entrega</h5>
            {leadTime.map((category) => (
              <S.Channel>
                <p>{getCategoryName(category.id)}</p>
                <p>{category.leadTime} dias</p>
              </S.Channel>
            ))}
          </div>

          <div>
            <h5>Datas comerciais</h5>
            {selectedDates.map((date) => (
              <p>
                {writeCommercialDate(date)} - {date.name}
              </p>
            ))}
          </div>

          <div>
            <h5>Curva de venda</h5>
            <p>{selectedCurve}</p>
          </div>
        </S.SectionList>
      </ModalBody>
      <S.ModalButtonSet noPadding>
        <S.Button kind="secondary" onClick={() => setShowSummaryModal(false)}>
          Revisar dados
        </S.Button>
        <S.Button primary onClick={() => navigate("/otb")}>
          Concluir
        </S.Button>
      </S.ModalButtonSet>
    </S.ComposedModal>
  );
}
