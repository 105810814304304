import React, { useState } from 'react';
import {
  TextInput,
  Select,
  SelectItem,
  Checkbox,
  ProgressIndicator,
  ProgressStep,
} from '@carbon/react';
import { ChevronRight } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';

import LayoutWithoutMenu from '../../../layouts/LayoutWithoutMenu/LayoutWithoutMenu';
import ButtonDefault from '../../../components/ButtonDefault/ButtonDefault';

import * as S from './stepTwoAccountCreation.style';

import { selectItemsCompany, selectItemsEmployees } from './items';

export default function StepTwoAccountCreation() {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/createAccountIntegration');
  };

  return (
    <LayoutWithoutMenu>
      <S.progressWrapper>
        <ProgressIndicator>
          <ProgressStep
            complete
            label='Autenticação'
            description='Passo 1: Quem é você?'
            secondaryLabel='Precisamos conhecê-lo'
          />
          <ProgressStep
            current
            label='Cadastro'
            description='Passo 2: Se cadastrando em Sumply'
            secondaryLabel='Precisamos de detalhes'
          />
          <ProgressStep
            label='Integrações'
            description='Passo 2: Conectar dados'
            secondaryLabel='Precisamos de dados!'
          />
        </ProgressIndicator>
      </S.progressWrapper>
      <S.formWrapper>
        <S.form onSubmit={handleSubmit}>
          <TextInput
            type='text'
            id='TextName'
            placeholder='Seu Nome'
            labelText=''
            invalid={false}
            required
          />
          <TextInput
            type='text'
            id='TextCompany'
            placeholder='Nome da sua empresa'
            labelText=''
            invalid={false}
            required
          />
          <Select id={`select-company-sector`} labelText='' defaultValue='000'>
            <SelectItem
              key='company-null-000'
              value='000'
              text='Setor da sua empresa'
            />
            {selectItemsCompany.map(({ valor, item }) => (
              <SelectItem key={`${valor}-${item}`} value={valor} text={item} />
            ))}
          </Select>
          <Select
            id={`select-number-employees`}
            labelText=''
            defaultValue='000'
          >
            <SelectItem
              key='employees-null-000'
              value='000'
              text='Número de funcionários'
              checked
            />
            {selectItemsEmployees.map(({ valor, item }) => (
              <SelectItem key={`${valor}-${item}`} value={valor} text={item} />
            ))}
          </Select>
          <div style={{ marginTop: '25px' }}>
            <Checkbox
              id='checkbox'
              labelText='Eu aceito participar da lista de e-mails da Sumply'
              checked={isChecked}
              required
              onChange={(_, { checked }) => setIsChecked(checked)}
            />
          </div>
          <ButtonDefault
            type='submit'
            className='submitBtn'
            renderIcon={() => <ChevronRight />}
          >
            Avançar
          </ButtonDefault>
        </S.form>
      </S.formWrapper>
    </LayoutWithoutMenu>
  );
}
