import styled from "styled-components";
import { globalStyles } from "../../consts/globalStyles";
import ButtonDefault from "../../components/ButtonDefault/ButtonDefault";
import { Link as LinkUI } from "@carbon/react";

const main = styled.div`
  padding-top: 3rem;
  min-height: 100vh;
  display: grid;
  align-items: center;
`;

const formRadio = styled.div`
  margin-top: ${globalStyles.size.lg};
  .radio {
    margin-bottom: 10px;
  }
`;

const code = styled.div`
  color: var(--text-text-secondary, #525252);

  /* Utility styles/code-01 */
  font-family: IBM Plex Mono;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.32px;
`;
const Link = styled(LinkUI)`
color: ${globalStyles.colors.linkPrimary};
`;

const formGroup = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 3px;
  margin-top: ${globalStyles.size.lg};
  #number-valor {
    margin-top: 24px;
  }
`;

const ButtonGreen = styled(ButtonDefault)`
  background-color: ${globalStyles.colors.buttonPrimary};
  color: #000;
  &:hover {
    opacity: 0.8;
    background-color: ${globalStyles.colors.buttonPrimary};
    color: #000;
  }
`;
const infoCard = styled.div`
  padding: 1rem;
  background: var(--cds-layer);
  color: var(--cds-text-primary, #A8A8A8);
  text-align: center;
`;

export { code, main, formRadio, formGroup, ButtonGreen, Link, infoCard };
