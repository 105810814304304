import React, { useContext, useState } from "react";
import { TextInput } from "@carbon/react";

import * as S from "./TargetSection.style";

import { CreatePlanContext } from "../../../../contexts/CreatePlanContext";

export default function TargetSection() {
  const { target, setTarget } = useContext(CreatePlanContext);

  const formatNumberWithDots = (numberStr) => {
    const cleanedStr = numberStr.replace(/\D/g, "");

    let reversedStr = cleanedStr.split("").reverse().join("");
    let formattedReversedStr = reversedStr.replace(/(\d{3})(?=\d)/g, "$1.");
    return formattedReversedStr.split("").reverse().join("");
  };

  const handleChange = (e, setTarget) => {
    const formattedValue = formatNumberWithDots(e.target.value.substring(3));
    setTarget(formattedValue);
  };

  return (
    <S.Container>
      <h4>Meta</h4>

      <div>
        <S.Label>Meta para este período</S.Label>
        <TextInput
          value={`R$ ${target}`}
          onChange={(e) => handleChange(e, setTarget)}
        />
      </div>

      <div>
        <S.Label>Meta para 2024</S.Label>
        <TextInput
          value={`R$ VALOR AQUI`}
          disabled
          style={{ border: "1px solid #d9d7d7" }}
        />
      </div>
    </S.Container>
  );
}
